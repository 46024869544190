import {View, StyleSheet} from 'react-native';
import DrawStatus from '../draw-staus/draw-status';
import React, {ReactNode} from 'react';
import LazyImage from '@/components/basic/image';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import {useTranslation} from 'react-i18next';
import theme from '@/style';
import WonResult from '../won/won';
import {DATA_MAP} from '../draw-staus';
import {toPriceStr} from '@utils';
import ShareButton from './share-btn';

export interface DetailCardProps {
  status: 1 | 0 | 2 | 3 | 4;
  id?: string;
  amount?: number;
  rightEle?: React.ReactElement | null;
  name?: string;
  awardNumber?: number;
  tip?: string | React.ReactElement;
  result?: string | React.ReactElement;
  betTime?: string;
  gameLogo: string | number;
  canGoDetail?: boolean;
  hasResult?: boolean;
  hasResultTip?: boolean;
  betResult?: ReactNode;
  onCopy?: () => void;
  onPress?: () => void;
  hasShare?: boolean;
  onShare?: () => void;
  hasAward?: boolean;
}

const DetailCard = ({
  canGoDetail = false,
  gameLogo,
  name,
  tip,
  rightEle,
  amount = 0,
  status = 0,
  awardNumber = 0,
  hasResultTip = true,
  betTime,
  hasResult,
  result,
  betResult,
  onCopy,
  onPress,
  id,
  hasShare = false,
  onShare,
  hasAward = false,
}: DetailCardProps) => {
  const {i18n} = useTranslation();
  const Ele = canGoDetail ? NativeTouchableOpacity : View;
  return (
    <Ele onPress={onPress}>
      <DrawStatus
        colors={DATA_MAP[status].bg}
        textColor={DATA_MAP[status].color}
        label={i18n.t(`bets-page.status.${DATA_MAP[status].text}`)}
        isDetail
        id={id}
        onCopy={onCopy}
      />
      <View
        style={[
          theme.flex.row,
          theme.flex.between,
          theme.flex.centerByCol,
          theme.margin.topl,
        ]}>
        <View
          style={[theme.flex.row, theme.flex.centerByCol, theme.flex.flex1]}>
          <View style={styles.cover}>
            <LazyImage
              imageUrl={gameLogo}
              width={48}
              height={48}
              occupancy="transparent"
            />
          </View>
          <View style={[theme.margin.lrl, theme.flex.flex1]}>
            <Text
              fontFamily="fontInterBold"
              size="medium"
              numberOfLines={1}
              color={theme.basicColor.dark}>
              {name}
            </Text>
            {React.isValidElement(tip) ? tip : <Text>{tip}</Text>}
          </View>
        </View>
        {rightEle ? (
          rightEle
        ) : (
          <View style={[theme.flex.alignEnd]}>
            <Text fontWeight="500">{i18n.t('label.payment')}</Text>
            <Text size="medium" fontFamily="fontInterBold">
              {toPriceStr(amount)}
            </Text>
          </View>
        )}
      </View>
      <View style={styles.line} />
      <View>
        <View style={[theme.flex.row, theme.flex.between]}>
          <Text fontWeight="500" accent>
            {i18n.t('bets-page.label.bettingTime')}
          </Text>
          <Text fontWeight="500">{betTime}</Text>
        </View>
      </View>
      {hasResult && (
        <View style={[theme.flex.row, theme.flex.between, theme.margin.topl]}>
          <Text fontWeight="500" accent>
            Result
          </Text>
          {React.isValidElement(result) ? (
            result
          ) : (
            <Text fontWeight="500">{result}</Text>
          )}
        </View>
      )}
      {hasShare && (
        <View
          style={[
            theme.flex.row,
            theme.flex.between,
            theme.flex.centerByCol,
            theme.margin.topl,
          ]}>
          <Text fontWeight="500" accent>
            Share
          </Text>
          <ShareButton onShare={onShare} hasAward={hasAward} />
        </View>
      )}
      {betResult}
      {(status === 1 || status === 4) && awardNumber > 0 && hasResultTip && (
        <WonResult awardAmount={awardNumber} />
      )}
      {(status === 0 || (status === 4 && awardNumber === 0)) &&
        hasResultTip && (
          <View style={[theme.padding.topl]}>
            <Text textAlign="center" fontSize={15} primary>
              {i18n.t('game-page.label.guessWrong')}
            </Text>
          </View>
        )}
      {status === 3 && hasResultTip && (
        <View style={[theme.padding.topl]}>
          <Text textAlign="center" fontSize={15} primary>
            {i18n.t('game-page.label.ticketSetting')}
          </Text>
        </View>
      )}
    </Ele>
  );
};

const styles = StyleSheet.create({
  borderRadius: {
    borderRadius: 20,
  },
  cover: {
    width: 48,
    height: 48,
    borderRadius: 8,
    overflow: 'hidden',
  },
  line: {
    backgroundColor: '#D8E0EA',
    height: 1,
    marginVertical: 12,
  },
});

export default DetailCard;
