import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native';
import Text from '@/components/basic/text';
import LazyImage from '@/components/basic/image';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ShareButton from './share-btn';
import {toPriceStr} from '@/utils';
import {DrawStatus, DATA_MAP} from '../draw-staus';
import Svg, {Line} from 'react-native-svg';
import WonResult from '../won/won';
const copyIcon = require('@components/assets/icons/bets/copy.webp');

export interface BetsCardType {
  status: 0 | 1 | 2 | 3 | 4;
  cover: string | number;
  tag?: string;
  tagTwo?: string;
  title?: string;
  id?: string;
  betTime?: string;
  drawTime?: string;
  hasShare?: boolean;
  hasAward?: boolean;
  rightEle?: React.ReactElement | null;
  payment?: number;
  reward?: number;
  canGoDetail?: boolean;
  content?: React.ReactElement | string;
  result?: React.ReactElement | null;
  onShare?: () => void;
  onCopy?: () => void;
  goDetail?: () => void;
  awardAmount?: number;
  style?: StyleProp<ViewStyle>;
  statusTip?: string;
  hasResult?: boolean;
}

const BetsCard = (props: BetsCardType) => {
  const {
    status,
    id = '',
    onCopy,
    cover,
    title,
    tag,
    tagTwo,
    payment = 0,
    betTime,
    drawTime,
    reward = 0,
    canGoDetail = true,
    hasShare = false,
    onShare,
    style = {},
    hasAward = false,
    goDetail = () => {},
    result,
    statusTip,
    hasResult = true,
  } = props;
  const {i18n} = useTranslation();
  const Ele = canGoDetail ? NativeTouchableOpacity : View;
  return (
    <Ele onPress={goDetail} style={[theme.margin.topl, style]}>
      <View
        style={[
          {backgroundColor: theme.backgroundColor.betsBgDark},
          // eslint-disable-next-line react-native/no-inline-styles
          {borderTopLeftRadius: 12, borderTopRightRadius: 12},
          theme.padding.btml,
        ]}>
        <View style={[theme.flex.row, theme.padding.l, theme.flex.centerByCol]}>
          <View style={styles.cover}>
            <LazyImage
              imageUrl={cover}
              width={48}
              height={48}
              occupancy="transparent"
            />
          </View>
          <View
            style={[
              theme.flex.flex1,
              theme.margin.leftl,
              theme.flex.row,
              theme.flex.centerByCol,
              theme.flex.between,
            ]}>
            <View style={[theme.flex.flex1]}>
              <Text
                color={theme.basicColor.white}
                size="medium"
                fontFamily="fontInterBold"
                numberOfLines={1}>
                {title}
              </Text>
              <View>
                <Text color={theme.basicColor.white} fontWeight="500">
                  Draw time
                </Text>
                <Text color={theme.basicColor.white} fontFamily="fontInterBold">
                  {drawTime}
                </Text>
              </View>
            </View>
            <View>
              {!!tag && (
                <View
                  style={[
                    theme.padding.lrl,
                    theme.padding.tbxxs,
                    // eslint-disable-next-line react-native/no-inline-styles
                    {backgroundColor: '#FFFFFF4D', borderRadius: 20},
                  ]}>
                  <Text
                    color={theme.basicColor.white}
                    fontFamily="fontInterBold">
                    {tag}
                  </Text>
                </View>
              )}
              {!!tagTwo && (
                <View
                  style={[
                    theme.padding.lrl,
                    theme.padding.tbxxs,
                    theme.margin.topxxs,
                    // eslint-disable-next-line react-native/no-inline-styles
                    {backgroundColor: '#FFFFFF4D', borderRadius: 20},
                  ]}>
                  <Text
                    color={theme.basicColor.white}
                    fontFamily="fontInterBold">
                    {tagTwo}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
      <View
        style={[
          theme.background.white,
          theme.padding.topxl,
          theme.overflow.hidden,
          {
            borderRadius: theme.paddingSize.xxl,
            marginTop: -theme.paddingSize.xxl,
          },
        ]}>
        <View style={[theme.flex.row, theme.flex.between, theme.padding.lrl]}>
          <View style={[theme.flex.flex1]}>
            <View
              style={[
                theme.flex.row,
                theme.flex.centerByCol,
                {gap: theme.paddingSize.s},
              ]}>
              <Text accent fontWeight="500">
                {i18n.t('label.order')} {i18n.t('label.id')}
              </Text>
              <Text fontWeight="500">{id}</Text>
              <NativeTouchableOpacity onPress={onCopy}>
                <LazyImage
                  imageUrl={copyIcon}
                  width={14}
                  height={14}
                  occupancy="transparent"
                />
              </NativeTouchableOpacity>
            </View>
            <View
              style={[
                theme.flex.row,
                theme.flex.centerByCol,
                {gap: theme.paddingSize.s},
                theme.margin.tops,
              ]}>
              <Text accent fontWeight="500">
                {i18n.t('bets-page.label.bettingTime')}
              </Text>
              <Text fontWeight="500">{betTime}</Text>
            </View>
          </View>
          <View style={[theme.flex.alignEnd]}>
            <Text accent fontWeight="500">
              {i18n.t('label.payment')}
            </Text>
            <Text
              size="large"
              fontFamily="fontInterBold"
              color={theme.basicColor.dark}>
              {toPriceStr(payment, {fixed: 2})}
            </Text>
          </View>
        </View>
        <View
          style={[
            theme.flex.row,
            theme.flex.between,
            theme.flex.centerByCol,
            theme.margin.tbxs,
          ]}>
          <View style={[styles.circle, styles.left]} />
          <View style={[theme.flex.flex1]}>
            <Svg width={'100%'} height={1}>
              <Line
                x1="0"
                y1="0"
                x2={'100%'}
                y2="0"
                stroke="#9FA5AC"
                strokeWidth="2"
                strokeDasharray={'6,6'}
              />
            </Svg>
          </View>
          <View style={[styles.circle, styles.right]} />
        </View>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            theme.padding.lrl,
            theme.flex.between,
          ]}>
          <Text fontFamily="fontInterBold" size="medium">
            {i18n.t('game-page.label.myNum')}
          </Text>
          {hasShare && <ShareButton onShare={onShare} hasAward={hasAward} />}
        </View>
        <View
          style={[theme.padding.lrl, theme.padding.tops, theme.margin.btml]}>
          <DrawStatus
            // eslint-disable-next-line react-native/no-inline-styles
            style={[{paddingLeft: 0}]}
            colors={DATA_MAP[status].bg}
            textColor={DATA_MAP[status].color}
            label={i18n.t(`bets-page.status.${DATA_MAP[status].text}`)}
            id={id}
            tip={statusTip}
            onCopy={onCopy}
          />
          {result}
          {hasResult && (
            <>
              {(status === 1 || status === 4) && reward > 0 && (
                <WonResult
                  style={[theme.padding.topxxl, theme.margin.btms]}
                  awardAmount={reward}
                />
              )}
              {(status === 0 || (status === 4 && reward === 0)) && (
                <View style={[theme.padding.topxxl, theme.padding.btmm]}>
                  <Text textAlign="center" fontSize={15} primary>
                    {i18n.t('game-page.label.guessWrong')}
                  </Text>
                </View>
              )}
            </>
          )}
        </View>
      </View>
    </Ele>
  );
};

const styles = StyleSheet.create({
  borderRadius: {
    borderRadius: 20,
  },
  cover: {
    width: 48,
    height: 48,
    borderRadius: 8,
    overflow: 'hidden',
  },
  tag: {
    // backgroundColor: '#EA1200',
    paddingVertical: 2,
    borderRadius: 2,
    paddingHorizontal: 3,
    marginRight: 4,
  },
  line: {
    height: 1,
    marginTop: 12,
    backgroundColor: theme.backgroundColor.grey,
  },
  item: {
    marginTop: 12,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  shareBtn: {
    padding: 4,
    borderRadius: 4,
    backgroundColor: theme.backgroundColor.main,
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: theme.backgroundColor.lightGrey,
  },
  left: {
    marginLeft: -4,
  },
  right: {
    marginRight: -4,
  },
});

export default BetsCard;
