import theme from '@/style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React from 'react';
import {KeyboardAvoidingView, View, StyleSheet} from 'react-native';
import BetsHeader from './bets-header';
import BetsPrices from './bets-price';
import Button from '@/components/basic/button';
import Text from '@/components/basic/text';
import {toPriceStr} from '@/utils';
import BetsQuantity from './bsts-quantity';
import {Svg, Rect, Circle} from 'react-native-svg';
import {useShareToast} from '@/common-pages/hooks/shareToast.hooks';
// bets 下注 color | dice 使用

export interface BetsProps {
  onClose?: () => void;
  BallEle?: null | React.ReactElement;
  onSeeRule?: () => void;
  onBitSubmit?: (price: number) => void;
}

const Bets = ({
  onClose = () => {},
  BallEle,
  onSeeRule,
  onBitSubmit,
}: BetsProps) => {
  const {agreeTip} = useShareToast();
  const [agree, setAgree] = React.useState(true);
  const [selectedPrice, setSelectedPrice] = React.useState(10);
  const [rate, setRate] = React.useState(1);

  const totalPrice = React.useMemo(() => {
    return selectedPrice * rate;
  }, [selectedPrice, rate]);

  const handlePay = () => {
    if (!agree) {
      // onSeeRule && onSeeRule();
      agreeTip();
      return;
    }
    if (totalPrice < 10) {
      return;
    }
    onBitSubmit && onBitSubmit(totalPrice);
  };

  return (
    <KeyboardAvoidingView behavior="padding">
      <BetsHeader onClose={onClose} />
      <View style={[theme.background.white, styles.container]}>
        <View style={[styles.ballContainer]}>{BallEle}</View>
        <BetsPrices
          onSelect={setSelectedPrice}
          selectedPrice={selectedPrice}
          prices={[10, 100, 500, 1000]}
        />
        <BetsQuantity stepValue={rate} onStep={setRate} />
        <View
          style={[theme.margin.btmxxl, theme.flex.row, theme.flex.centerByCol]}>
          <NativeTouchableOpacity
            style={[theme.margin.rightl]}
            onPress={() => setAgree(!agree)}>
            <Svg width={16} height={16}>
              <Circle
                cx="8"
                cy="8"
                r="8"
                fill={agree ? theme.basicColor.primary : '#9FA5AC'}
              />
              <Rect
                x="4.91406"
                y="6.65332"
                width="4.94301"
                height="2"
                rx="1"
                transform="rotate(45 4.91406 6.65332)"
                fill="white"
              />
              <Rect
                x="5.58301"
                y="10.1484"
                width="7.98862"
                height="2"
                rx="1"
                transform="rotate(-45 5.58301 10.1484)"
                fill="white"
              />
            </Svg>
          </NativeTouchableOpacity>
          <Text>I Agree</Text>
          <Text
            onPress={() => onSeeRule && onSeeRule()}
            color={theme.basicColor.primary}
            style={[
              theme.margin.lefts,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                textDecorationLine: 'underline',
              },
            ]}
            fontFamily="fontInterBold">
            (Pre-sale rules)
          </Text>
        </View>
        <Button type="linear-primary" size="large" onPress={handlePay}>
          <Text
            color={theme.basicColor.white}
            size="medium"
            fontFamily="fontInterBold">
            Total Price{' '}
            {toPriceStr(totalPrice, {
              fixed: 0,
            })}
          </Text>
        </Button>
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  ballContainer: {
    paddingVertical: 12,
    marginBottom: 12,
    ...theme.flex.center,
    backgroundColor: '#FFFCEB',
  },
});

export default Bets;
